.carrusel {
    height: 20vh;
    display: flex;
    align-items: center;
}

.carrusel-items {
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 30px 0px;
}

.carrusel-item {
    min-width: 300px;
    max-width: 300px;
    height: 200px;
    outline: 2px solid black;
    cursor: pointer;
}

.carrusel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carrusel-item:hover {
    transform: scale(1.2);
}