@import url('https://fonts.googleapis.com/css2?family=DynaPuff:wght@400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
/*font-family: "Bebas Neue", cursive;*/

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

body {
    font-family: "Poppins", sans-serif;
    background-color: rgb(9, 9, 39);
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.header {
    background-image: url(../imagenes/bg9.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
    display: flex;
    align-items: center;
    transition: background-image 0.5s ease;
}
.header.hovered {
    background-image: url(../imagenes/bg.jpg);
}
.menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}

.logo {
    font-size: 24px;
    color: rgb(24, 24, 160);
    font-weight: 800;
    text-transform: uppercase;
}

.menu .navbar ul li {
    position: relative;
    float: left;
}

.menu .navbar ul li a {
    font-size: 18px;
    padding: 20px;
    color: rgb(236, 132, 47);
    display: block;
    font-weight: normal;
}

.menu .navbar ul li a:hover {
    color: rgb(24, 24, 160);
}

#menu {
    display: none;
}

.menu-icono {
    width: 25px;

}

.menu-logo{

    width: 120px;
    filter: drop-shadow( 0 0 10px rgb(0, 10, 0,10));
} 

.menu label {
    cursor: pointer;
    display: none;
}

.header-txt {
    text-align: center;
    margin-bottom: 45px;
}

.header-txt h1, span, h3 {
    font-size: 110px;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-family: "Bebas Neue", cursive;
    color: rgb(24, 24, 160);
    text-shadow: 1px  0px 0px #FFFFFF,
    0px  1px 0px #FFFFFF,
   -1px  0px 0px #FFFFFF,
    0px -1px 0px #FFFFFF;
    margin-bottom: 15px;
}

.mapzoom header-txt h1, span, h3{
     font-size: 20px;
    letter-spacing: 5px;
    
    
  
    color: rgb(24, 24, 160);
}


.header-txt span {
    font-size: 25px;
}
.header-txt p {
    font-size: 20px;
    color:#FFFFFF;
    text-shadow: 0.8px  0px 0px black,
                 0px  0.8px 0px black,
                -1px  0px 0px black,
                 0px -1px 0px black;
    padding: 0 250px;
    margin: 25px 0;
}
.btn-1 {
    display: inline-block;
    padding: 11px 25px;
    background-color: #D82E37;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 20px;
    font-family: "Bebas Neue", cursive;
    margin: 25px 0;
    border-radius: 5px;
    text-decoration: none;
}
.btn-1:hover {
    background-color: #841D22;
}
.header-dir {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-top: 20px;
}
.dir h3 {
    font-size: 18px;
    font-weight: bold;
}
.dir p {
   font-size: 22px; 
   color: #FFFFFF;
   
   text-shadow: 1px  0px 0px black,
                0px  1px 0px black,
               -1px  0px 0px black,
                0px -1px 0px black;
   text-transform: uppercase;
}
.welcome {
    display: flex;
}
.welcome-1 {
    background-image: url(../imagenes/a1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
}
.welcome-2 {
    width: 50%;
    padding: 150px 250px 150px 100px;
    background-color: rgb(9, 9, 51);
}
h2 {
    font-size: 90px;
    line-height: 100px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: "Bebas Neue", cursive;
    margin-bottom: 20px;
    text-align: center;
}
p {
    font-size: 16px;
    color: #AFAFAF;
    margin: 25px 0;
}
.p1 {
    color: rgb(236, 132, 47);
    text-align: justify;
}
.services {
    padding: 100px 0;
    text-align: center;
}
.services-txt {
    display: flex;
    flex-direction: column;
    align-items: center;
}
hr {
    border: 1px solid #CF2D3A;
    width: 80px;
}
.services-txt p {
    padding: 50px 150px;
}
.services-group {
    display: flex;
    justify-content: space-between;
    margin: 45px 0;
}
.services-1 {
    background-color: #070707;
    padding: 35px 25px;
    margin: 0 10px;
    border-radius: 15px;
    width: 100%; 
    max-width: 100%; 
    margin-bottom: 20px;
    box-sizing: border-box; 
    text-align: center; 
}
.services-1 video {
    width: 50%;
    max-width: 100%;
    display: block; 
    margin: 0 auto; 
    outline: none; 
    border-radius: 8px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}
.services-1 img {
    width: 40px;
    margin-bottom: 15px;
}
.services-1 h3 {
    font-size: 25px;
    color: #FFFFFF;
}

.services-1 h4 {
    font-size: 20px;
    color: orange;
   
    
}
.services-1 a {
    color: #CF2D3A;
    font-size: 16px;
    text-decoration: none;
}
.prices {
    display: flex;
}
.prices-1 {
    width: 50%;
    padding: 150px 100px 150px 100px;
    background-color: rgb(9, 9, 51);
}

table {
    margin: 50px 0;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}
tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #AFAFAF;
    margin-bottom: 20px;
}

.tatra{
    display: table-row;
    text-align: center;
  

}

.tatrd{
    display: table-row;
    text-align: center;
    
}
th {
    color: #FFFFFF;
    padding-bottom: 15px;
}
td {
    color: #CF2D3A;
}
.prices-2 {
    background-image: url(../imagenes/a2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    
    width: 50%;
    padding: 150px 10px 20px 10px;
    
}
.busdni{
    color: #FFFFFF;
}


.personal {
    pad: 100px 0;
    text-align: center;
}
.personal-txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 25px;
}
.personal-gruop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    
    
}
.personal-1 {
    text-align: center;
}
.personal-1 img {
    width: 250px;
    border-radius: 15px;
    margin-bottom: 25px;
}
.personal-1 h3 {
    font-size: 22px;
}
.personal-1 p {
    padding: 0 50px;
}
.personal-1 a {
    color: #CF2D3A;
}

.footer-bg {
    background-image: url(../imagenes/bg-2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 100px 0;
    
}
form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.campo-1 {
    margin-bottom: 15px;
}
.campo {
    margin: 5px;
    padding: 20px 15px;
    width: 250px;
    background-color: #0e0e0e;
    border: 1px solid #CF2D3A;
    outline: none;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 14px;
}
textarea {
    width: 520px !important;
}
.footer-txt {
    text-align: center;
    padding: 10px;
    background-color: #070707;
}
.footer-txt a {
    color: #CF2D3A; 
    text-decoration: none; 
    font-weight: bold; 
}
.audio-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #CF2D3A;
    padding: 10px;
    border-radius: 10px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center; /* Alinear el contenido horizontalmente */
    box-shadow: 0 5px 8px #020202;
}

.audio-container audio {
    width: 200px;
    background-color: #CF2D3A;
    border: none;
    outline: none;
}

.audio-container audio::-webkit-media-controls-panel {
    background-color: #CF2D3A;
    border-radius: 5px;
    box-shadow: none;
}

.audio-container audio::-webkit-media-controls-play-button,
.audio-container audio::-webkit-media-controls-volume-slider {
    color:#000000;
}
.social-icons {
    position: fixed;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
}
.social-icons a {
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    transition: transform 0.3s ease; 
}
.social-icons a:hover {
    transform: scale(2.0); 
}
.social-icons a img {
    width: 24px;
    height: 24px;
}
@media(max-width:991px) {
    .menu {
        padding: 20px;
    }
    .menu label {
        display: initial;
    }
    .menu .navbar {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #1f1f1f;
        display: none;
    }
    .menu .navbar ul li {
        width: 100%;
    }
    #menu:checked ~ .navbar {
        display: initial;
    }
    .header{
        min-height: 0vh;
    }
    .header-content {
        padding: 80px 30px 50px 30px;
    }
    .header-txt {
        margin-bottom: 15px;
    }
    .header-txt h1 {
        font-size: 90px;
    }
    .header-txt p {
        padding: 0;
        margin: 15px 0;
    }
    .header-dir {
        flex-direction: column;
    }
    .dir p {
        font-size: 18px;
    }
    .welcome {
        flex-direction: column;
    }
    .welcome-1 {
        display: none;
    }
    .welcome-2 {
        width: 100%;
        padding: 30px;
        text-align: center;
    }
    h2 {
        font-size: 70px;
        line-height: 80px;
    }
    p {
        margin: 15px 0;
    }
    .services {
        padding: 30px;
    }
    .services-txt p {
        padding: 0;
    } 
    .services-group {
        flex-direction: column;
        margin: 0;
    }
    .services-1 {
        margin: 0 0 20px 0;
    }
    .prices {
        flex-direction: column;
    }
    .prices-1 {
        width: 100%;
        padding: 30px;
        text-align: center;
    }
    table {
        margin: 25px 0 0;
    }
    .prices-2 {
        display: none;
    }
    .personal {
        padding: 30px;
    }
    .personal-gruop {
        flex-direction: column;
        margin-bottom: 0;
    }
    .personal-1 {
        margin-bottom: 25px;
    }
    .footer-bg {
        padding: 30px;
        margin: 100px 200px 100px 200px;
    }
    textarea {
        width: 250px !important;
    }
}


.blinking-icon {
    animation: blinkingAnimation 1s infinite;
    border: 2px solid red;
    border-radius: 50%;
}

@keyframes blinkingAnimation {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.datos{
    color: orange;
}

.concepto{
    color: white;
}

 
.confreser{
    color: black;
    
}

.custom-table {
    background-color: #D3D3D3; /* Fondo gris */
    color: black; /* Letras negras */
  }
  
  .custom-table thead {
    background-color: #B0B0B0; /* Un tono más oscuro de gris para el encabezado */
  }
  
  .custom-table th,
  .custom-table td {
    border-color: black; /* Borde negro */
  }

.concepto2{
    color: orange;
    font-weight: bolder;
    text-shadow: 1px  0px 0px #080808,
    0px  1px 0px #080808,
   -1px  0px 0px #080808,
    0px -1px 0px #080808;
}